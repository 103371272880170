<template>
  <div class="interviews">
    <header-table
      :title="$t('dashboard.allRequests')"
      :createNewTitle="$t('dashboard.createNewRequest')"
      :createNew="true"
      @createHandler="orderDialog = true"
      @updateDate="items = $event.ordes"
      url="orders/search"
    ></header-table>

    <v-data-table
      :headers="headers"
      :items="items"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      @page-count="pageCount = $event"
      :mobile-breakpoint="0"
    >
      <!-- status -->
      <template v-slot:item.number_of_positions_needed="{ item }">
        <div
          class="px-2 py-1 radius-10 d-inline-block"
          :class="getColorBg(item.number_of_positions_needed)"
        >
          <span
            class="font-16 font-700"
            :class="getColorText(item.number_of_positions_needed)"
            >{{ item.number_of_positions_needed }}</span
          >
        </div>
      </template>
      <!-- status -->
      <template v-slot:item.not_started="{ item }">
        <div
          class="px-2 py-1 radius-10 d-inline-block"
          :class="getColorBg(item.not_started)"
        >
          <span
            class="font-16 font-700"
            :class="getColorText(item.not_started)"
            >{{ item.not_started }}</span
          >
        </div>
      </template>
      <!-- status -->
      <template v-slot:item.in_progress="{ item }">
        <div
          class="px-2 py-1 radius-10 d-inline-block"
          :class="getColorBg(item.in_progress)"
        >
          <span
            class="font-16 font-700"
            :class="getColorText(item.in_progress)"
            >{{ item.in_progress }}</span
          >
        </div>
      </template>
      <!-- status -->
      <template v-slot:item.interview="{ item }">
        <div
          class="px-2 py-1 radius-10 d-inline-block"
          :class="getColorBg(item.interview)"
        >
          <span
            class="font-16 font-700"
            :class="getColorText(item.interview)"
            >{{ item.interview }}</span
          >
        </div>
      </template>
      <!-- status -->
      <template v-slot:item.closed="{ item }">
        <div
          class="px-2 py-1 radius-10 d-inline-block"
          :class="getColorBg(item.closed)"
        >
          <span class="font-16 font-700" :class="getColorText(item.closed)">{{
            item.closed
          }}</span>
        </div>
      </template>
      <!-- status -->
      <template v-slot:item.declined="{ item }">
        <div
          class="px-2 py-1 radius-10 d-inline-block"
          :class="getColorBg(item.declined)"
        >
          <span class="font-16 font-700" :class="getColorText(item.declined)">{{
            item.declined
          }}</span>
        </div>
      </template>

      <!-- actions -->
      <template v-slot:item.actions="{ item }">
        <div class="d-flex align-center">
          <img
            @click="deleteHandler(item.id)"
            class="mr-3 cursor-pointer"
            :src="require('@/assets/images/dashboard/icons/minus-circle.svg')"
          />
          <img
            class="mx-3 cursor-pointer"
            :src="require('@/assets/images/dashboard/icons/info.svg')"
            @click="$router.push(`/dashboard/orders/${item.id}`)"
          />
          <v-btn
            color="register-primary"
            dark
            outlined
            class="hover-primary-btn"
            elevation="0"
            @click="$router.push(`/dashboard/order/${item.id}/doctors`)"
          >
            <span class="font-16 register-primary--text">{{$t('dashboard.assignedoctors')}}</span>
          </v-btn>
        </div>
      </template>
    </v-data-table>

    <!-- new order dialog -->
    <v-dialog
      v-model="orderDialog"
      max-width="500px"
      :content-class="isMobile ? 'pa-4 radius-5 white' : 'pa-6 white radius-16'"
    >
      <new-order
        :info="orderInfo"
        @close="orderDialog = false"
        @updateData="getData"
        :key="orderDialog"
      ></new-order>
    </v-dialog>

    <!-- delete dialog -->
    <v-dialog v-model="deleteDialog" max-width="400" content-class="white">
      <delete-item
        @close="deleteDialog = false"
        @ok="okHandler"
        url="order/delete"
        :obj="selectedDeleteObj"
        sentence="Are you sure want to decline this order ?"
      ></delete-item>
    </v-dialog>
  </div>
</template>

<script>
import DeleteItem from "../../../../components/core/DeleteItem.vue";
import NewOrder from "../../components/dialogs/NewOrder.vue";
import HeaderTable from "../../components/HeaderTable.vue";
export default {
  components: { HeaderTable, NewOrder, DeleteItem },
  data: (vm) => ({
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    headers: [
      {
        text: vm.$t("dashboard.providerSpecialization"),
        sortable: false,
        value: "specialization",
      },
      {
        text: vm.$t("dashboard.numberOfPositions"),
        value: "number_of_positions_needed",
        sortable: false,
      },
      {
        text: vm.$t("dashboard.healthCareProvider"),
        value: "health_care_provider",
        sortable: false,
      },
      {
        text: vm.$t("dashboard.notStarted"),
        value: "not_started",
        sortable: false,
      },
      {
        text: vm.$t("dashboard.inProgress"),
        value: "in_progress",
        sortable: false,
      },
      {
        text: vm.$t("dashboard.interview"),
        value: "interview",
        sortable: false,
      },
      { text: vm.$t("dashboard.closed"), value: "closed", sortable: false },
      { text: vm.$t("dashboard.declined"), value: "declined", sortable: false },
      { text: vm.$t("dashboard.actions"), value: "actions", sortable: false },
    ],
    items: [],

    orderDialog: false,
    orderInfo: {},

    deleteDialog: false,
    selectedDeleteObj: {},
  }),
  methods: {
    getColorText(status) {
      if (status == 0) {
        return "error--text";
      } else {
        return "bluetextdash--text";
      }
    },
    getColorBg(status) {
      if (status == "0") {
        return "errorbg";
      } else {
        return "bluebgdashlight";
      }
    },
    async getData() {
      this.orderDialog = false;
      let { data } = await this.$axios("orders");
      if (data) {
        this.items = data.data.orders;
      }
    },
    deleteHandler(id) {
      this.selectedDeleteObj = { key: "order_id", id };
      this.deleteDialog = true;
    },
    async okHandler() {
      this.deleteDialog = false;
      await this.getData();
    },
    async getOrderInfo() {
      let { data } = await this.$axios("hospital/information");
      if (data) {
        this.orderInfo = data.data;
      }
    },

  },
  async created() {
    await this.getData();
    await this.getOrderInfo();
  },
};
</script>

<style lang="scss">
.interviews {
  border-radius: 15px;
  background: white;
  padding: 8px;
  thead {
    background: $bluebgdash;
    padding-left: 10px;
    span {
      font-size: 14px;
      font-weight: 600;
      color: #202a4f;
    }
  }
  tbody tr:nth-child(even) {
    background-color: $bluebgdash;
  }
  thead,
  tbody {
    tr {
      th,
      td {
        &:first-child {
          padding-left: 30px !important;
        }
      }
    }
    tr:hover {
      background-color: transparent !important;
    }
  }
  tbody {
    tr {
      height: 70px;
    }
    tr:hover {
      background-color: transparent !important;
    }
  }
}
td,
th {
  border-bottom: none !important;
}
</style>
