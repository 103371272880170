<template>
  <div class="bluebgdash header-table">
    <div class="d-flex align-center justify-space-between">
      <!-- head title -->
      <span class="register-primary--text font-16 font-600">{{ title }}</span>
      <div class="d-flex align-center">
        <div class="custom-pagination">
          <!-- <v-pagination v-model="page" :length="pageCount"></v-pagination> -->
        </div>
      </div>
    </div>

    <!-- inputs -->
    <div class="d-flex align-center mt-3">
      <v-row align="center">
        <v-col cols="12" md="3">
          <div class="radius-10 white py-0">
            <v-text-field
              :hide-details="true"
              dense
              class="table-input px-3"
              color="register-primary"
              :placeholder="$t('forms.search')"
              append-icon="mdi-magnify"
              background-color="white"
              filled
              @keyup="searchHandler"
              v-model="searchInput"
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="12" md="7" class="px-3">
          <span class="register-primary--text font-500" v-if="loadingSearch"
            >{{ $t("dashboard.loading") }}...</span
          >
        </v-col>
        <v-col v-if="createNew" cols="12" md="2">
          <v-btn
            color="register-primary"
            dark
            block
            class="radius-5 primary-btn-shadow"
            height="40px"
            @click="$emit('createHandler')"
          >
            <div class="d-flex align-center justify-space-between w-100">
              <span class="font-16 white--text">{{ createNewTitle }}</span>
              <span class="font-18 font-700 white--text">+</span>
            </div>
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    createNew: {
      type: Boolean,
      default: false,
    },
    createNewTitle: {
      type: String,
      default: "",
    },
    url: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    displays: [
      { title: "5 rows per page", value: 5 },
      { title: "10 rows per page", value: 10 },
      { title: "20 rows per page", value: 20 },
      { title: "30 rows per page", value: 30 },
    ],
    searchInput: "",
    page: 1,
    pageCount: 1,
    timer: undefined,
    loadingSearch: false,
  }),
  methods: {
    async searchHandler() {
      this.loadingSearch = true;
      clearTimeout(this.timer);
      this.timer = setTimeout(async () => {
        let { data } = await this.$axios.post(this.url, {
          value: this.searchInput,
        });
        if (data.success) {
          this.$emit("updateDate", data.data);
        }
        this.loadingSearch = false;
      }, 1500);
    },
  },
  created() {},
};
</script>

<style lang="scss">
.header-table {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 16px 24px;

  @media (max-width: 960px) {
    padding: 0px;
  }
}
.custom-pagination {
  .v-pagination {
    li {
      display: none;
      margin: 0px 15px;
      transition: 0.3s;
      cursor: pointer;

      &:hover {
        background: #cdfff6;
      }
      &:last-child,
      &:first-child {
        display: block;
      }

      &:first-child {
        padding: 0px 10px;

        button {
          width: max-content;
          box-shadow: none;
          background: transparent;
          &::after {
            color: #33588f;
            font-size: 14px;
          }
          i {
            color: #33588f;
            font-size: 18px;
            margin-bottom: 2px;
          }
        }
      }
      &:last-child {
        padding-left: 10px;
        margin: 0px;
        button {
          width: max-content;
          box-shadow: none;
          background: transparent;
          &::before {
            color: #33588f;
            font-size: 14px;
          }
          i {
            color: #33588f;
            font-size: 18px;
            margin-bottom: 2px;
          }
        }
      }
    }
  }
}
.table-input {
  padding-top: 0px;
  margin-top: 0px;

  .v-input__slot {
    &::before,
    &::after {
      display: none;
    }
  }
  .v-select__selections {
    padding-left: 10px;
  }
}
</style>
