var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dashbase-input dashbase-auto relative",class:_vm.isCountrySelect ? 'auto-complete' : ''},[_c('span',{staticClass:"d-block font-16 font-600 darktext--text relative mb-1"},[_vm._v(_vm._s(_vm.title)+" "),(_vm.isRequired)?_c('span',{staticClass:"error--text"},[_vm._v("*")]):_vm._e()]),_c('v-autocomplete',{key:_vm.isUpdating,attrs:{"items":_vm.items,"outlined":"","value":_vm.value,"dense":"","hide-details":true,"filter":_vm.filterHandler,"placeholder":_vm.placeholder,"multiple":_vm.multiple,"readonly":_vm.readOnly,"background-color":"#F8F8F8","color":"register-primary","append-icon":_vm.hasIcon ? 'mdi-magnify' : '',"item-text":_vm.itemText,"item-value":_vm.itemValue,"rules":[
      _vm.rules.length == 0
        ? null
        : _vm.multiple
        ? (v) => v.length > 0 || 'this field is require'
        : (v) => !!v || 'this field is require',
    ],"menu-props":{ closeOnContentClick: _vm.closed }},on:{"input":function($event){return _vm.inputHandler($event)}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({staticClass:"mb-1",class:_vm.multiple ? 'lightregister-primary radius-15' : 'transparent',attrs:{"input-value":data.selected,"label":"","close":_vm.multiple,"dark":""},on:{"click:close":function($event){return _vm.$emit('removeHandler', data.item)}}},'v-chip',data.attrs,false),[_c('span',{staticClass:"darkprimary--text font-16 font-500 d-flex align-center"},[_vm._v(_vm._s(data.item.name))])])]}},{key:"append-item",fn:function(){return [_c('span',{staticClass:"v-list-fixed white--text register-primary",on:{"click":_vm.closeHandler}},[_vm._v(_vm._s(_vm.$t("forms.done")))])]},proxy:true},{key:"item",fn:function(data){return [_c('span',_vm._b({},'span',data.attrs,false),[_vm._v(_vm._s(data.selected))]),_c('v-list-item-content',{staticClass:"font-16",domProps:{"textContent":_vm._s(data.item.name)}})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }