<template>
  <div>
    <div class="d-flex align-center justify-space-between mb-4">
      <span
        class="d-block font-700 darkbord--text"
        :class="isMobile ? 'font-18' : 'font-24 '"
      >
        {{ $t("forms.addNewRequest") }}
      </span>
      <v-icon @click="$emit('close')">mdi-close</v-icon>
    </div>
    <v-form v-model="valid" @submit.prevent="submitHandler" ref="addOrderForm">
      <v-row>
        <!-- Specializations -->
        <v-col cols="12" :key="specialKey">
          <base-auto-complete
            :items="info.specializations"
            v-model="obj.specializations"
            itemText="name"
            :multiple="true"
            :placeholder="$t('forms.specializations')"
            @removeHandler="removeExperienceHandler"
          ></base-auto-complete>
        </v-col>
        <!-- Experience -->
        <v-col cols="12">
          <base-auto-complete
            :items="info.experience"
            v-model="obj.experience_id"
            itemText="name"
            :placeholder="$t('forms.experience')"
            :title="$t('forms.experience')"
            :key="obj.experience_id"
            @input="experienceHandler($event, 'experience')"
          ></base-auto-complete>
        </v-col>
        <!-- Healthcare Provider Type -->
        <v-col cols="12">
          <base-auto-complete
            :items="info.healthCare"
            v-model="obj.healthcare_provider_type_id"
            itemText="name"
            :title="$t('forms.healthcareProviderType')"
            :placeholder="$t('forms.healthcareProviderType')"
            @input="experienceHandler"
          ></base-auto-complete>
        </v-col>
        <!-- Job type -->
        <v-col cols="12">
          <base-auto-complete
            :placeholder="$t('forms.jobType')"
            :title="$t('forms.jobType')"
            :items="info.jobType"
            v-model="obj.job_type_id"
          ></base-auto-complete>
        </v-col>
        <!-- Job Urgency -->
        <v-col cols="12">
          <base-auto-complete
            :placeholder="$t('forms.jobUrgency')"
            :title="$t('forms.jobUrgency')"
            :items="info.jobUrgency"
            v-model="obj.job_urgency_id"
          ></base-auto-complete>
        </v-col>
        <!-- Gender -->
        <v-col cols="12">
          <base-auto-complete
            :placeholder="$t('forms.gender')"
            :title="$t('forms.gender')"
            :items="genders"
            v-model="obj.gender"
          ></base-auto-complete>
        </v-col>
        <!-- Education level -->
        <v-col cols="12">
          <base-auto-complete
            :placeholder="$t('forms.educationLevel')"
            :title="$t('forms.educationLevel')"
            :items="info.degree"
            v-model="obj.degree_id"
          ></base-auto-complete>
        </v-col>
        <!-- Country of education -->
        <v-col cols="12">
          <base-auto-complete
            :placeholder="$t('forms.countryOfEducation')"
            :title="$t('forms.countryOfEducation')"
            :items="info.countries"
            v-model="obj.university_or_institution_country_id"
            :rules="[]"
          ></base-auto-complete>
        </v-col>
        <!-- Number of position needed -->
        <v-col cols="12">
          <base-input
            :placeholder="$t('forms.numberOfPositionNeeded')"
            :title="$t('forms.numberOfPositionNeeded')"
            type="number"
            v-model="obj.number_of_positions_needed"
          ></base-input>
        </v-col>
        <!-- Additional information -->
        <v-col cols="12">
          <base-textarea
            :placeholder="$t('forms.additionalInformation')"
            :title="$t('forms.additionalInformation')"
            v-model="obj.additional_information"
            :rules="[]"
          ></base-textarea>
        </v-col>

        <v-row class="mt-4 px-3">
          <v-col cols="4">
            <v-btn
              outlined
              color="#333333"
              elevation="0"
              class="radius-5"
              height="44px"
              @click="$emit('close')"
              block
              ><span class="font-700 font-16">
                {{ $t("forms.discard") }}
              </span></v-btn
            >
          </v-col>
          <v-col cols="8">
            <v-btn
              color="register-primary"
              dark
              elevation="0"
              class="radius-5"
              height="44px"
              type="submit"
              block
              ><span class="font-700 font-16">{{
                $t("forms.save")
              }}</span></v-btn
            >
          </v-col>
        </v-row>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import BaseAutoComplete from "../BaseAutoComplete.vue";
import BaseInput from "../BaseInput.vue";
import BaseTextarea from "../BaseTextarea.vue";
export default {
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
  components: { BaseInput, BaseTextarea, BaseAutoComplete },
  data: (vm) => ({
    valid: false,
    genders: [
      { name: vm.$t("forms.male"), id: "male" },
      { name: vm.$t("forms.female"), id: "female" },
      { name: vm.$t("forms.any"), id: "any" },
    ],
    postionNeeded: [
      { name: "1", id: "1" },
      { name: "2", id: "2" },
      { name: "3", id: "3" },
      { name: "4", id: "4" },
      { name: "5", id: "5" },
      { name: "6", id: "6" },
      { name: "7", id: "7" },
    ],
    obj: { number_of_positions_needed: 1 },
    specialKey: false,
    keyUpdated: false,
  }),
  methods: {
    experienceHandler(event, type) {
      if (type == "experience") {
        if (event == 5) {
          this.obj.healthcare_provider_type_id = 1;
        }
      } else {
        this.obj.experience_id = 0;
        if (event == 1) {
          this.obj.experience_id = 5;
        }
      }
    },
    async submitHandler() {
      let valid = this.$refs.addOrderForm.validate();
      if (!valid) {
        setTimeout(() => {
          document
            .querySelector(".v-input.error--text:first-of-type")
            .scrollIntoView({
              behavior: "smooth",
            });
        }, 100);
        this.$store.dispatch("showSnack", {
          text: this.$t("rules.checkAllFields"),
          color: "error",
        });
        return;
      }
      let formData = new FormData();

      formData.append(
        "healthcare_provider_type_id",
        this.obj.healthcare_provider_type_id
      );
      formData.append("experience_id", this.obj.experience_id);
      formData.append("job_type_id", this.obj.job_type_id);
      formData.append("job_urgency_id", this.obj.job_urgency_id);
      formData.append("gender", this.obj.gender);
      formData.append("degree_id", this.obj.degree_id);
      formData.append(
        "university_or_institution_country_id",
        this.obj.university_or_institution_country_id
      );
      formData.append(
        "number_of_positions_needed",
        this.obj.number_of_positions_needed
      );
      formData.append(
        "additional_information",
        this.obj.additional_information
      );

      this.obj.specializations.forEach((item, idx) => {
        formData.append(`specializations[${idx}]`, item);
      });

      let { data } = await this.$axios.post("order/create", formData);

      if (data.success) {
        this.$store.dispatch("showSnack", {
          text: "Added Successfully",
          color: "success",
        });
        this.$emit("updateData");
      } else {
        this.$store.dispatch("showSnack", {
          text: "Something went wrong",
          color: "error",
        });
      }
    },
    removeExperienceHandler(event) {
      const index = this.obj.specializations.indexOf(event.id);
      if (index >= 0) this.obj.specializations.splice(index, 1);
    },
  },
};
</script>

<style></style>
