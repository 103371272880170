<template>
  <div
    class="dashbase-input dashbase-auto relative"
    :class="isCountrySelect ? 'auto-complete' : ''"
  >
    <span class="d-block font-16 font-600 darktext--text relative mb-1"
      >{{ title }}

      <span class="error--text" v-if="isRequired">*</span>
    </span>
    <v-autocomplete
      :items="items"
      outlined
      @input="inputHandler($event)"
      :value="value"
      dense
      :hide-details="true"
      :filter="filterHandler"
      :placeholder="placeholder"
      :multiple="multiple"
      :readonly="readOnly"
      background-color="#F8F8F8"
      color="register-primary"
      :append-icon="hasIcon ? 'mdi-magnify' : ''"
      :item-text="itemText"
      :item-value="itemValue"
      :rules="[
        rules.length == 0
          ? null
          : multiple
          ? (v) => v.length > 0 || 'this field is require'
          : (v) => !!v || 'this field is require',
      ]"
      :key="isUpdating"
      :menu-props="{ closeOnContentClick: closed }"
    >
      <template v-slot:selection="data">
        <v-chip
          v-bind="data.attrs"
          :input-value="data.selected"
          label
          :close="multiple"
          dark
          class="mb-1"
          :class="multiple ? 'lightregister-primary radius-15' : 'transparent'"
          @click:close="$emit('removeHandler', data.item)"
        >
          <span
            class="darkprimary--text font-16 font-500 d-flex align-center"
            >{{ data.item.name }}</span
          >
        </v-chip>
      </template>
      <!-- <template v-slot:append-outer>
        <v-slide-x-reverse-transition mode="out-in">
          <v-icon
            class="autocomplete-close cursor-pointer"
           
            >mdi-close</v-icon
          >
        </v-slide-x-reverse-transition>
      </template> -->

      <template #append-item>
        <span
          class="v-list-fixed white--text register-primary"
          @click="closeHandler"
          >{{ $t("forms.done") }}</span
        >
      </template>
      <template v-slot:item="data">
        <span v-bind="data.attrs">{{ data.selected }}</span>
        <!-- <v-checkbox ></v-checkbox> -->
        <v-list-item-content
          class="font-16"
          v-text="data.item.name"
        ></v-list-item-content>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      validator: () => true,
    },
    items: {
      type: Array,
      default: () => [],
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    hasIcon: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [1],
    },
    itemValue: {
      type: String,
      default: "id",
    },
    itemText: {
      type: String,
      default: "name",
    },
    placeholder: {
      type: String,
      default: "",
    },
    isCountrySelect: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    closeMenu: false,
    closed: false,
    isUpdating: false,
  }),
  methods: {
    removeHandler(data) {
      this.$emit("removeHandler", data.item);
    },
    inputHandler(event) {
      this.$emit("input", event);
      let input = document.querySelector(".v-select__selections input");
      setTimeout(() => {
        input.value = "";
      }, 30);
    },
    closeHandler() {
      this.closed = true;
      setTimeout(() => {
        this.closed = false;
      }, 100);
    },
    filterHandler(val, queryText) {
        if (val.name.toLowerCase().startsWith(queryText.toLowerCase())) {
          return val
        }
    },
  },
};
</script>

<style lang="scss">
.dashbase-auto {
  i {
    transform: rotate(0deg) !important;
  }
  .v-chip__content {
    white-space: pre-wrap;
  }
}
.v-list {
  position: relative !important;

  .v-list-fixed {
    position: sticky;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 40px;
    justify-content: center;
    display: flex;
    align-items: center;
    z-index: 9;
    cursor: pointer;
    margin-top: 10px;
  }
}
.auto-complete {
  height: 46px !important;
  .mb-1 {
    margin-bottom: 0px !important;
  }

  .v-input__control,
  .v-select__selections {
    max-height: 46px;
    overflow: hidden;
  }
}
</style>
