<template>
  <div class="delete-dialog">
    <div class="px-10 text-center">
      <span class="mt-10 d-block font-22 black--text font-500 mb-7">
        {{ $t("forms.wantToDelete") }}</span
      >
      <div class="mb-10">
        <v-btn
          class="c-btn mx-1"
          color="grey"
          height="40"
          @click="$emit('close')"
          ><span class="white--text font-300 font-20 d-inline-block px-7">{{
            $t("forms.no")
          }}</span></v-btn
        >
        <v-btn
          class="c-btn mx-1"
          color="secondary"
          height="40"
          @click="deleteHandler"
          ><span class="white--text font-300 font-20 d-inline-block px-7">{{
            $t("forms.yes")
          }}</span></v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      default: "",
    },
    sentence: {
      type: String,
      default: "",
    },
    obj: {
      type: String,
      default: "",
    },
  },
  methods: {
    async deleteHandler() {
      let formData = new FormData();
      formData.append(this.obj.key, this.obj.id);

      let { data } = await this.$axios.post(`${this.url}`, formData);
      if (data.success) {
        this.$store.dispatch("showSnack", {
          text: "Deleted Successfully",
          color: "success",
        });
        this.deleteDialog = false;
        this.$emit("ok");
      } else {
        this.$store.dispatch("showSnack", {
          text: data.message,
          color: "error",
        });
      }
    },
  },
};
</script>

<style lang="scss"></style>
