<template>
  <div class="dashbase-input">
    <span
      class="d-block font-16 font-600 darktext--text relative"
      v-if="!noTitle"
      >{{ title }}

      <span class="error--text font-700" v-if="isRequired">*</span>
    </span>

    
    <v-text-field
      dense
      :type="type"
      height="45px"
      :hide-details="hideDetails"
      :rules="rules"
      :placeholder="placeholder"
      class="radius-5 "
      outlined
      :clearable="isClear"
      color="register-primary"
      clear-icon="mdi-close"
      background-color="#F8F8F8"
      :append-icon="appendIcon"
      @click:clear="clearMessage"
      :value="value"
      :min="0"
      @input="$emit('input', $event)"
    ></v-text-field>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      validator: () => true,
    },
    type: {
      type: String,
      default: "text",
    },
    title: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    appendIcon: {
      type: String,
      default: "",
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    noTitle: {
      type: Boolean,
      default: false,
    },
    isClear: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
    rules: {
      type: Array,
      default: () => [(v) => !!v || "this field is require"],
    },
  },
  methods: {
    clearMessage() {},
  },
};
</script>

<style lang="scss">
.dashbase-input {
  span {
    min-height: 26px;
  }
  fieldset {
    border-color: #d8d8d87e !important;
  }
}
</style>
